<template>
  <div class="vx-alert-builder">
    <div class="vx-row items-center mb-5">
      <div class="vx-col w-full sm:w-1/3">
        <div class="vs-input--label">
          <div class="inline-block">
            <vs-tooltip text="Team members who will receive the alert notification">
              <div class="inline-flex items-center">
                <feather-icon icon="InfoIcon" svgClasses="h-3 w-3 mr-2" />
                <span>Send to</span>
              </div>
              <span></span>
            </vs-tooltip>
          </div>
        </div>
      </div>
      <div class="vx-col w-full sm:w-2/3">
        <vx-field
          ref="vxFieldSendTo"
          type="select"
          class="w-full"
          autocomplete
          :multiple="true"
          v-model="contactsValue"
          @input="updateValue"
          :options="{
            items: [
              {
                value: -1,
                text: 'Everyone'
              },
              ...team
            ]
          }"
        />
        <!-- :disabled="team.length === 1" -->
      </div>
    </div>
    <div class="vx-row items-center mb-5">
      <div class="vx-col w-full sm:w-1/3">
        <div class="vs-input--label">
          <div class="inline-block">
            <vs-tooltip text="Tiny changes (ex: a word) happens below 5%, a medium change (a section) around 8-10% and major changes above 20%">
              <div class="inline-flex items-center">
                <feather-icon icon="InfoIcon" svgClasses="h-3 w-3 mr-2" />
                <span>Alert change threshold</span>
              </div>
              <span></span>
            </vs-tooltip>
          </div>
        </div>
      </div>
      <div class="vx-col w-full sm:w-2/3">
        <div class="pl-2 pr-12 flex items-center">
          <vs-slider text-fixed="%" v-model="form.changePercent" />
          <span class="ml-4 text-sm">{{ form.changePercent || 0 }}%</span>
        </div>
      </div>
    </div>
    <div class="vx-row items-center mb-5">
      <div class="vx-col w-full sm:w-1/3">
        <div class="vs-input--label">
          <div class="inline-block">
            <vs-tooltip
              text="We will alert you if downloading the HTML (excludes downloading of images, JS, etc) for the page takes more than this time."
            >
              <div class="inline-flex items-center">
                <feather-icon icon="InfoIcon" svgClasses="h-3 w-3 mr-2" />
                <span>Max Download Time</span>
              </div>
              <span></span>
            </vs-tooltip>
          </div>
        </div>
      </div>
      <div class="vx-col w-full sm:w-2/3">
        <div class="pl-2 pr-12 flex items-center">
          <vs-slider text-fixed="ms" v-model="form.maxDownloadTimeMs" step="250" max="60000" />
          <span class="ml-4 text-sm">{{ form.maxDownloadTimeMs || 0 }}ms</span>
        </div>
      </div>
    </div>
    <div class="vx-row items-center mb-5">
      <div class="vx-col w-full sm:w-1/3">
        <div class="vs-input--label">
          <div class="inline-block">
            <vs-tooltip
              text="We will alert you if download the HTML, images, JS and other resources and rendering the screenshot takes longer than this time."
            >
              <div class="inline-flex items-center">
                <feather-icon icon="InfoIcon" svgClasses="h-3 w-3 mr-2" />
                <span>Max Capture Time</span>
              </div>
              <span></span>
            </vs-tooltip>
          </div>
        </div>
      </div>
      <div class="vx-col w-full sm:w-2/3">
        <div class="pl-2 pr-12 flex items-center">
          <vs-slider text-fixed="ms" v-model="form.maxCaptureTimeMs" step="250" max="60000" />
          <span class="ml-4 text-sm">{{ form.maxCaptureTimeMs || 0 }}ms</span>
        </div>
      </div>
    </div>
    <div class="vx-row items-center mb-base">
      <div class="vx-col w-full sm:w-1/3">
        <div class="vs-input--label">
          <div class="inline-block">
            <vs-tooltip text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minus, eaque recusandae. Earum.">
              <div class="inline-flex items-center">
                <feather-icon icon="InfoIcon" svgClasses="h-3 w-3 mr-2" />
                <span>Severity</span>
              </div>
              <span></span>
            </vs-tooltip>
          </div>
        </div>
      </div>
      <div class="vx-col w-full sm:w-2/3">
        <vx-field
          type="select"
          class="w-full"
          autocomplete
          v-model="form.severity"
          :options="{
            items: severityOptions
          }"
        />
      </div>
    </div>
    <div class="flex">
      <vs-button v-if="form.id" type="border" color="danger" icon-pack="feather" icon="icon-trash" @click="deleteAlert">Delete</vs-button>
      <vs-button class="ml-auto" color="success" icon-after icon-pack="feather" icon="icon-check" @click="submit">Save Changes</vs-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import EmailAlert from '@/entity/EmailAlert';

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: new EmailAlert(this.data),
      severityOptions: EmailAlert.severityOptions
    };
  },
  computed: {
    ...mapState('accounts', {
      team: state =>
        (state.team.items || []).map(item => ({
          value: item.id,
          text: item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : item.email
        }))
    }),
    contactsValue: {
      get() {
        const { contacts } = this.form;
        if (contacts.length === 0) return [-1];
        return contacts.map(item => {
          if (typeof item === 'object') return item.id;
          return item;
        });
      },
      set(ids) {
        console.log(ids);
        if (ids.length > 1 && ids.includes(-1)) {
          const indexOfNull = ids.indexOf(-1);
          if (indexOfNull === 0) ids.splice(0, 1);
          else return (this.form.contacts = []);
        }
        return (this.form.contacts = ids);
      }
    }
  },
  methods: {
    async submit() {
      const monitor = this.$attrs.monitor;
      const contacts = this.form.contacts.includes(-1) ? undefined : this.form.contacts;
      const result = await this.$store.dispatch('monitors/alert', {
        monitor,
        alert: {
          ...this.form,
          contacts
        }
      });

      if (result && result.id) {
        this.$emit('close');
      }
    },
    updateValue() {
      const { contacts } = this.form;
      if (contacts.length === 0) {
        this.$nextTick(() => {
          const vxFieldSendTo = this.$refs.vxFieldSendTo;
          const fields = vxFieldSendTo.$refs;
          for (const name in fields) {
            if (fields.hasOwnProperty(name)) {
              const field = fields[name];
              field.changeValue();
            }
          }
        });
      }
    },
    async deleteAlert() {
      await this.$store.dispatch('monitors/deleteAlert', (this.data || {}).id);
      this.$emit('close');
    }
  },
  async created() {
    await this.$store.dispatch('accounts/team');
  }
};
</script>
