<template>
  <section class="monitor-view-area">
    <div class="vx-row">
      <div class="vx-col w-full md:w-3/5">
        <vx-card v-if="item.url">
          <CalendarHeatMap slot="no-body" ref="captureChanges" :hideFilter="true" :data="item" :compare="false" :range="changesRange" />
        </vx-card>
        <vx-data-list
          class="vx-data-list-captures"
          ref="vxDataList"
          :hoverFlat="true"
          :data="list"
          :columns="columns"
          :search="false"
          :config="{
            disablePager: true,
            selectable: false
          }"
        />
      </div>
      <div class="vx-col w-full md:w-2/5">
        <vx-card class="mb-5">
          <div class="vx-row">
            <div class="vx-col w-full">
              <vx-field
                type="datetime"
                class="w-full"
                placeholder="Date range"
                v-model="form.datecaptured"
                icon="icon-calendar"
                @on-change="dateChanged"
                :config="{
                  dateFormat: 'd-m-Y',
                  enableTime: false,
                  mode: 'range',
                  ...dateSelectionLimit
                }"
              >
                <div slot="append" class="append-text"> ({{ item.lastCaptureDate | moment('z', monitorTz) }}) </div>
              </vx-field>
            </div>
            <!-- <div class="vx-col w-full  mb-5">
              <vx-field
                type="switch"
                :inline="true"
                size="large"
                class="w-full"
                label="Fullpage"
                v-model="form.captureEntireHeight"
                v-validate="'required'"
                :config="{
                  on: {
                    icon: 'icon-check-circle',
                    text: 'Yes'
                  },
                  off: {
                    icon: 'icon-slash',
                    text: 'No'
                  }
                }"
              />
            </div>
            <div class="vx-col w-full  mb-5">
              <vx-field
                type="switch"
                :inline="true"
                size="large"
                class="w-full"
                label="Detected changes only"
                v-model="form.group"
                v-validate="'required'"
                :config="{
                  on: {
                    icon: 'icon-check-circle',
                    text: 'On'
                  },
                  off: {
                    icon: 'icon-slash',
                    text: 'Off'
                  }
                }"
              />
            </div>
            <div class="vx-col w-full  mb-5">
              <vx-field
                type="switch"
                :inline="true"
                size="large"
                class="w-full"
                label="Group captures"
                v-model="form.changes"
                v-validate="'required'"
                :config="{
                  on: {
                    icon: 'icon-check-circle',
                    text: 'On'
                  },
                  off: {
                    icon: 'icon-slash',
                    text: 'Off'
                  }
                }"
              />
            </div> -->
          </div>
        </vx-card>
        <vx-card class="mb-5">
          <div class="vx-row text-sm">
            <div class="vx-col w-full sm:w-1/2 mb-5">
              <p class="font-semibold mb-2">URL</p>
              <p class="truncate">
                <a :href="item.url | urlPath" target="_blank">
                  <vs-icon icon-pack="feather" icon="icon-link" />
                  {{ item.url | urlPath }}
                </a>
              </p>
            </div>
            <div class="vx-col w-full sm:w-1/2 mb-5">
              <p class="font-semibold mb-2">Frequency</p>
              <p>{{ item.schedule | humanDescription(monitorTz) }}</p>
            </div>
            <div class="vx-col w-full sm:w-1/2 mb-5">
              <p class="font-semibold mb-2">Last Capture</p>
              <p>
                <span v-if="item.lastCaptureDate">{{ item.lastCaptureDate | moment('DD MMM, YYYY HH:mm (z)', monitorTz) }}</span>
                <span v-else>N/a</span>
              </p>
            </div>
            <div class="vx-col w-full sm:w-1/2 mb-5">
              <p class="font-semibold mb-2">Next Capture</p>
              <p>
                <span v-if="item.nextCaptureAfter">{{ item.nextCaptureAfter | moment('DD MMM, YYYY HH:mm (z)', monitorTz) }}</span>
                <span v-else>N/a</span>
              </p>
            </div>
            <div class="vx-col w-full sm:w-1/2">
              <p class="font-semibold mb-2">Timezone</p>
              <p>
                <vs-icon icon-pack="feather" icon="icon-globe" />
                {{ item.timeZone }}
              </p>
            </div>
          </div>
        </vx-card>
        <vx-card class="mb-5" v-if="item.alertRules">
          <div v-if="item.alertRules.length > 0">
            <div v-for="(alert, i) in item.alertRules" :key="i" class="vx-row text-sm items-center mb-3" :class="{ 'mt-3': i > 0 }">
              <div class="vx-col w-auto flex-1">
                <p class="flex">
                  <vs-icon
                    icon-pack="feather"
                    :icon="alert.enabled > 0 ? 'icon-bell' : 'icon-bell-off'"
                    style="margin-top: -2px"
                    class="mr-2 transform translate-y-1"
                  ></vs-icon
                  >{{ alert | alertDescription }}</p
                >
                <!-- <p class="pl-6">{{ alert | alertDescription }} </p> -->
              </div>
              <div class="vx-col w-auto">
                <div class="flex justify-end items-center">
                  <vx-field
                    type="switch"
                    size="large"
                    class="mr-3"
                    :value="alert.enabled > 0"
                    @click="toggleMonitorAlert(alert)"
                    :config="{
                      on: { text: 'On' },
                      off: { text: 'Off' }
                    }"
                  />
                  <feather-icon
                    icon="SettingsIcon"
                    svgClasses="h-5 w-5 hover:text-primary stroke-current cursor-pointer"
                    @click="openEmailAlertForm(alert)"
                  />
                </div>
              </div>
            </div>
            <vs-button type="border" @click="openEmailAlertForm()" size="small">New Alert</vs-button>
          </div>
          <div v-else class="vx-row text-sm items-center cursor-pointer" @click="openEmailAlertForm()">
            <div class="vx-col w-auto">
              <p class="font-semibold"> <vs-icon icon-pack="feather" icon="icon-bell-off" class="mr-2"></vs-icon>Email Alerts</p>
            </div>
            <div class="vx-col w-auto flex-1">
              <div class="flex justify-end items-center">
                <vx-field
                  type="switch"
                  size="large"
                  class="pointer-events-none mr-3"
                  :value="item.alertRules.length > 0"
                  :config="{
                    on: { text: 'On' },
                    off: { text: 'Off' }
                  }"
                />
                <feather-icon icon="SettingsIcon" svgClasses="h-5 w-5 hover:text-primary stroke-current pointer-events-none" />
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import CalendarHeatMap from '@/components/CalendarHeatMap.vue';
import CaptureThumbnail from '@/views/captures/components/CaptureThumbnail';
import EmailAlert from '@/views/monitoring/components/EmailAlert';

export default {
  components: {
    CalendarHeatMap
  },
  data() {
    return {
      form: {
        datecaptured: `${this.$moment()
          .subtract(1, 'year')
          .format('DD-MM-YYYY')} to ${this.$moment().format('DD-MM-YYYY')}`
      }
    };
  },
  computed: {
    ...mapState('captures', ['list']),
    ...mapState('monitors', ['item']),
    dateSelectionLimit() {
      if (!this.form.datecaptured) return {};
      const [x] = this.form.datecaptured.split('to');
      const strFormat = 'DD-MM-YYYY';
      const minDate = this.$moment(x.trim(), strFormat)
        .subtract(1, 'year')
        .toDate();
      const maxDate = this.$moment(x.trim(), strFormat)
        .add(1, 'year')
        .toDate();
      return {
        minDate,
        maxDate
      };
    },
    changesRange() {
      if (!this.form.datecaptured) return null;
      // if (!this.form.datecaptured.includes('to')) return null;
      const [x, y] = this.form.datecaptured.split('to');
      const strFormat = 'DD-MM-YYYY';
      return {
        from: this.$moment(x.trim(), strFormat).toDate(),
        to: this.$moment((y || x).trim(), strFormat).toDate()
      };
    },
    monitorTz() {
      return this.item.timeZone;
    },
    columns() {
      return [
        {
          component: CaptureThumbnail,
          timeZone: this.monitorTz
        }
      ];
    }
  },
  methods: {
    async filterCaptures() {
      const { monitorTz, form, $moment } = this;
      const dates = form.datecaptured || '';

      const [x, y] = dates.split('to');
      const start = $moment
        .tz(x.trim(), 'DD-MM-YYYY', monitorTz)
        .startOf('day')
        .utc()
        .startOf('day')
        .format();
      const end = $moment
        .tz((y || x).trim(), 'DD-MM-YYYY', monitorTz)
        .endOf('day')
        .utc()
        .endOf('day')
        .format();

      return await this.$store.dispatch('captures/list', {
        params: {
          'monitor.id': this.$route.params.id,
          datecaptured: [start, end].join('/')
        }
      });
    },
    openEmailAlertForm(alert) {
      this.$vxPopup.open({
        attrs: {
          title: 'Email Alert'
        },
        config: {
          component: EmailAlert,
          monitor: this.item,
          data: alert || {}
        }
      });
    },
    async toggleMonitorAlert(alert) {
      await this.$store.dispatch('monitors/alert', {
        monitor: this.item,
        alert
      });
    },
    /* eslint no-unused-vars: 0 */
    dateChanged([from, to], valueStr) {
      if (to) {
        this.form.datecaptured = valueStr;
        this.$nextTick(() => {
          this.$clearTooltip();
          this.filterCaptures();
          this.$refs.captureChanges.fetchChanges();
        });
      }
    }
  },
  async created() {
    window.$moment = this.$moment;
    await this.$store.dispatch('monitors/item', { id: this.$route.params.id });
    this.$nextTick(() => {
      const { monitorTz, $moment } = this;
      this.form.datecaptured = `${$moment
        .tz(monitorTz)
        .subtract(1, 'year')
        .format('DD-MM-YYYY')} to ${$moment.tz(monitorTz).format('DD-MM-YYYY')}`;
      this.filterCaptures();
    });
  },
  watch: {
    // form: {
    //   deep: true,
    //   handler(params) {
    //     const props = JSON.parse(JSON.stringify(params));
    //     const dates = props.datecaptured || '';
    //     this.$clearTooltip();
    //     if (dates.includes('to')) {
    //       this.$nextTick(() => {
    //         this.filterCaptures();
    //         this.$refs.captureChanges.fetchChanges();
    //       })
    //     }
    //   }
    // }
  }
};
</script>
